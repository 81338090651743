// import React, { useState } from 'react';
// import axios from 'axios';
// import './Navbar.css'; // Import your CSS file
// import imageLogo from './imageLogo.png'
// import CustomNavbar from './Navbar';
// const App = () => {
//   const [attachment, setAttachment] = useState(''); // Attachment content
//   const headers = {
//     "Access-Control-Allow-Origin": "*",
//   }
//   const sendEmail = async () => {
//     try {
//       const response = await axios.post(
//         'https://us-central1-genzvision-8e0d5.cloudfunctions.net/sendEmailWithAttachment',
//         {
//           to: 'venkateshnatarajan786@gmail.com',
//           subject: 'Test Subject',
//           message: 'This is a test email.',
//         },headers,
        
//       );
//       console.log(response.data);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <div>
//    <CustomNavbar/>
//       <p>sdnsd</p>
//    </div>
//   );
// };

// export default App;


// App.js

// import React from 'react';
// import './App.css'; // Import your CSS file

// const App = () => {
//   return (
//     <div className="app-container">
//       <header>
//         <h1>My Responsive React Page</h1>
//       </header>
//       <main>
//         <section className="content">
//           <p>This is the main content of the page.</p>
//         </section>
//         <aside className="sidebar">
//           <p>This is the sidebar content.</p>
//         </aside>
//       </main>
//       <footer>
//         <p>&copy; 2024 My React App</p>
//       </footer>
//     </div>
//   );
// };

// export default App;



// App.js

import React, { useEffect, useRef, useState } from 'react';
import Navbar from './Navbar';
import './App.css';   // Import your CSS file

import { useLocation } from "react-router-dom";


import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {db} from './firebase';

import { get, ref, set,update } from "firebase/database";
import TableOfContentApp from './app/TableOfContent';
import Statistics from './app/Statistics';
import BeAwareOf from './app/BeAwareOf';
import EffectsOfWorkPolitics from './app/EffectsWorkPolitics';
import WhatDoReadersSay from './app/WhatDoReadersSay';
import Globe from './app/Globe';
import AboutUS from './app/AboutUS';
import Warning from './app/Warning';
import ContactUs from './app/ContactUs';

const App = () => {
  const { merchantTransactionId,email ,data2 } = useParams();
  const navigate = useNavigate();
  const valueee = useLocation().state;
  const [SmallScreen, setSmallScreen] = useState(null)
  const [successDialog, setSuccessDialog] = useState(false)
  
  const sectionRefs = {
    section1: useRef(null),
    aboutUs: useRef(null),
    section3: useRef(null),
    whatWeOffer: useRef(null),
    whyChooseUs: useRef(null),
    section6: useRef(null),
    section7: useRef(null),
    contactUs: useRef(null),
    warning: useRef(null),
    tableOfContent:useRef(null),
    statistics:useRef(null),
    testimonials:useRef(null),
  };

  const closeDialog = () => {
    setSuccessDialog(false);
    navigate('/')
  }
useEffect(()=>{

  console.log("data22222",data2)
  if(data2 == 15276959 ){
    setSuccessDialog(true);
  }
},[])

  useEffect((email,merchantTransactionId,navigate,data2) => {



console.log("successDialog",successDialog)

    // Redirect to the specified URL
   
    // Access the width of the screen
    const screenWidth = window.screen.width;

    if (merchantTransactionId !== undefined && merchantTransactionId !==null && merchantTransactionId.length>0) {
        
      console.log("transaction is "+merchantTransactionId)
      const dataParam ={
        merchantTransactionId: merchantTransactionId,
        email:email
      }

const dataRef = ref(db,'users/'+merchantTransactionId);
get(dataRef).then((snapshot) => {
const data = snapshot.val()
    if (snapshot.exists() && !data.success) {
      axios.post(`https://us-central1-genzvision-8e0d5.cloudfunctions.net/phonePayStatus/${merchantTransactionId}/${email}`, {
        ...dataParam   
      }).then(res => { 
    navigate('/', { replace: true });

      console.log("resssssssss" +JSON.stringify(res))
        
      if (res.data.success) {

const dataRef = ref(db,'users/'+merchantTransactionId);
update(dataRef,{

  success:true
})
        
      }
        
      
      
      })
      .catch(error => {
          console.error("error",error);
      }); 
    }

})
        
     
    }



    console.log("Gotttt id :"+merchantTransactionId)
    console.log('Screen Width:', screenWidth);

    // You can perform additional logic based on the screen width
    if (screenWidth < 768) {
      setSmallScreen(true)
      // Do something for small screens
    } else {
      setSmallScreen(false)

      console.log('Large screen!');
      // Do something for large screens
    }
  }, []);
  const handleScroll = () => {
    // Find the current section by checking which one is in the viewport
    let currentSectionId = null;
    console.log("Handling scroll")
    for (const [sectionId, sectionRef] of Object.entries(sectionRefs)) {
      const rect = sectionRef.current.getBoundingClientRect();

      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        currentSectionId = sectionId;
      }
    }

    // Scroll to the next section
    if (currentSectionId) {
      const nextSectionId = getNextSectionId(currentSectionId);
      const nextSectionRef = sectionRefs[nextSectionId];

      if (nextSectionRef) {
        nextSectionRef.current.scrollIntoView({
          behavior: 'smooth', 
        });
      }
    }
  };
  const getNextSectionId = (currentSectionId) => {
    // Implement logic to determine the next section based on the current section
    // Example: section1 -> aboutUs, aboutUs -> section3, ..., sectionN -> section1
    // You can customize this logic according to your needs.

    const sectionNumbers = Object.keys(sectionRefs).map((key) =>
      parseInt(key.replace('section', ''), 10)
    );

    const currentSectionNumber = parseInt(
      currentSectionId.replace('section', ''),
      10
    );

    const nextSectionNumber =
      currentSectionNumber % sectionNumbers.length + 1;

    return `section${nextSectionNumber}`;
  };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  return (
    <div className="app-container">
      <Navbar />

      {successDialog?<div className='successDialogDiv'>
          <div class="billing-containerDialog">
    <div >
      {/* <span>Name:</span>
      <span>{formData.couponCode}</span> */}
    </div>
    <div >
      <span className='mainIntro'>THANK YOU!!!</span>
      <span>999</span>
    </div>
    <div >
      <span className='sucessDialogDesc'>You will be added to the community group shortly...</span><br/><br/>
      <span className='sucessDialogDesc'>Please check your mail box with an email subject as below:<br/>[Confidential]- Copy of Work, politics & growth - by the Gen Z Vision<br/> community
      </span><br/><br/>
      <a onClick={closeDialog}> <button  className="buttonStyle">CLOSE</button></a>

    </div>
  </div>
          </div>:null}
        <div >

        <section id="section1" ref={sectionRefs.section1} className="centered-section-main">
       <br/>
        
        <div >
          <h1 className="mainIntro">WORK</h1>
          <h1 className="mainIntro" style={{color:'#FFA31A'}}>POLITICS</h1>
          <h1 className="mainIntro">&</h1>
          <h1 className="mainIntro" style={{color:'#FFA31A'}}>GROWTH</h1>
          <h6 className="mainIntroquote" >-- for beginners & mid-level professionals...</h6>
       
       </div>
       <br/> 
        </section >
     
        </div>
 <section id="aboutUs" ref={sectionRefs.aboutUs} >
 {/* <div
        style={{
          backgroundColor:'#F0DEA3',
                width:'100%',

          height:'0.5vh'
        }
        }
        >

        </div> */}
        <AboutUS/>

        {/* <div id="section1" ref={sectionRefs.section1}
        style={{
          backgroundColor:'#F0DEA3',
                   width:'100%',

          height:'0.5vh'
        }}
        >
        </div> */}
</section>

<section  >
        <Warning/>
        {/* <div
        style={{
          backgroundColor:'#F0DEA3',
          width:'100%',
          height:'0.5vh'
        }
        }
        >

        </div> */}
</section>

<section id="tableOfContent" ref={sectionRefs.tableOfContent} >
        <TableOfContentApp/>
        {/* <div
        style={{
          backgroundColor:'#F0DEA3',
                   width:'100%',

          height:'0.5vh'
        }
        }
        >

        </div> */}
</section>
<section id="statistics" ref={sectionRefs.statistics} >
        <Statistics/>
        {/* <div
        style={{
          backgroundColor:'#F0DEA3',
                   width:'100%',

          height:'0.5vh'
        }
        }
        >

        </div> */}
</section>
<section id="section1" ref={sectionRefs.section1} >
        <BeAwareOf/>
        {/* <div
        style={{
          backgroundColor:'#F0DEA3',
                   width:'100%',

          height:'0.5vh'
        }
        }
        >

        </div> */}
</section>
<section >
        <EffectsOfWorkPolitics/>
        {/* <div
        style={{
          backgroundColor:'#F0DEA3',
                   width:'100%',

          height:'0.5vh'
        }
        }
        >

        </div> */}
</section>
<section id="testimonials" ref={sectionRefs.testimonials} >
        <WhatDoReadersSay/>
        {/* <div
        style={{
          backgroundColor:'#F0DEA3',
                   width:'100%',

          height:'0.5vh'
        }
        }
        >

        </div> */}
        </section>
        <section id="section1" ref={sectionRefs.section1} >
        <Globe/>
        {/* <div
        style={{
          backgroundColor:'#F0DEA3',
                   width:'100%',

          height:'0.5vh'
        }
        }
        >

        </div> */}
        </section>

        <section id="contactUs" ref={sectionRefs.contactUs} >
        <ContactUs/>
</section>
        {/* Add more sections or components as needed */}
    </div>
  );
};

export default App;
